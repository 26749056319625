document.addEventListener('DOMContentLoaded', function() {
    var dropdownTriggers = document.querySelectorAll('.dropdown .header__title, .dropdown .header__link');

    dropdownTriggers.forEach(function(trigger) {
        trigger.addEventListener('focus', function() {
            this.nextElementSibling.style.display = 'flex'; // Show dropdown
            this.nextElementSibling.style.opacity = 1;
        });

        trigger.addEventListener('blur', function() {
            this.nextElementSibling.style.display = 'none'; // Hide dropdown
            this.nextElementSibling.style.opacity = 0;
        });
    });

    var dropdownLinks = document.querySelectorAll('.dropdown-content a');

    dropdownLinks.forEach(function(link) {
        link.addEventListener('focus', function() {
            this.closest('.dropdown-content').style.display = 'flex'; // Keep dropdown open
            this.closest('.dropdown-content').style.opacity = 1;
        });

        link.addEventListener('blur', function(event) {
            // Delay hiding to check if next focused element is within the same dropdown
            setTimeout(() => {
                if (!this.closest('.dropdown').contains(document.activeElement)) {
                    this.closest('.dropdown-content').style.display = 'none';
                    this.closest('.dropdown-content').style.opacity = 0;
                }
            }, 0);
        });
    });
});