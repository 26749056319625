const counters = document.querySelectorAll('.stats__number');
const countersContainer = document.querySelectorAll('.stats');

if(countersContainer){
  const countersArray = Array.from(counters)
  const countersContainerArray = Array.from(countersContainer)
  const speed = 100; 

  function isCounterInViewport(element) {
    const bounding = element.getBoundingClientRect();

    if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    ) {
        return true;
    } else {
        return false;
    }
  }

  window.addEventListener('scroll', function (event) {
    countersContainerArray.forEach(item => {
      if (isCounterInViewport(item)) {
        countersArray.forEach(counter => {
          const updateCount = () => {
            const target = +counter.getAttribute('data-target');
            const count = +counter.textContent;
        
            const inc = target / speed;
        
            if (count < target) {
              counter.textContent = Math.ceil(count + inc).toString();
              setTimeout(updateCount, 200);
            } else {
              counter.textContent = Math.ceil(target).toString();
            }
          };
          updateCount()
        });
      }
    })
  }, false);
}
