const paginationElements = document.querySelectorAll(".pagination");

export function updatePagination(page: number, allPages: number, totalItems: number, itemsPerPage: number, hasNextPage: boolean, hasPreviousPage: boolean) {
  paginationElements.forEach(item => {
    if (totalItems < 1) {
      item.classList.add("pagination--hidden");
    } else {
      item.classList.remove("pagination--hidden");
    }

    const element: HTMLElement = item.querySelector(".pagination__inner");
    const text: HTMLElement = item.querySelector(".pagination__text");

    if (totalItems > itemsPerPage) {
      text.textContent = `Showing ${itemsPerPage * page >= totalItems ? totalItems : itemsPerPage * page} of ${totalItems} results`
    } else {
      text.textContent = `Showing ${totalItems} of ${totalItems} results`
    }

    const totalPages = allPages;

    element.innerHTML = createPagination(totalPages, page, hasNextPage, hasPreviousPage);

    disablePagination(element)
  });
}

function createPagination(totalPages: number, page: number, hasNextPage: boolean, hasPreviousPage: boolean) {
  let liTag: string = "";
  let active: string = "";
  let beforePage: number = page - 1;
  let afterPage: number = page + 1;

  // Add the "Previous" button
  if (page > 1 && hasPreviousPage) {
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__arrow prev"><i class="zmdi zmdi-chevron-left"></i></button>`;
  }

  // Add the first page if necessary (but not within the visible range)
  if (page > 2) {
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center first pagination__numb">1</button>`;
    if (page > 3) {
      liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__dots" tabindex="-1">...</button>`;
    }
  }

  // Adjust pages before and after the current one
  if (page === totalPages) {
    beforePage -= 2;
  } else if (page === totalPages - 1) {
    beforePage -= 1;
  }
  if (page === 1) {
    afterPage += 2;
  } else if (page === 2) {
    afterPage += 1;
  }

  // Ensure beforePage is not less than 1
  beforePage = Math.max(beforePage, 1);
  // Ensure afterPage is not greater than totalPages
  afterPage = Math.min(afterPage, totalPages);

  // Create pagination numbers for the range
  for (let plength = beforePage; plength <= afterPage; plength++) {
    // Ensure we don't add duplicate first or last page
    if (plength === 1 && page > 2) continue; // Skip adding '1' in the range if it's already handled
    if (plength === totalPages && page < totalPages - 1) continue; // Skip adding last page in the range

    active = page === plength ? "pagination__btn--active" : "";
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__numb ${active}">${plength}</button>`;
  }

  // Add the last page if necessary (but not within the visible range)
  if (page < totalPages - 1) {
    if (page < totalPages - 2) {
      liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__dots" tabindex="-1">...</button>`;
    }
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center last pagination__numb">${totalPages}</button>`;
  }

  // Add the "Next" button
  if (page < totalPages && hasNextPage) {
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__arrow next"><i class="zmdi zmdi-chevron-right"></i></button>`;
  }

  return liTag;
}

export function disablePagination(element: HTMLElement) {
  const buttons: NodeListOf<HTMLButtonElement> = element.querySelectorAll("button");

  buttons.forEach(button => {
    button.disabled = true;
  });
}

export function enablePagination(element: HTMLElement) {
  const buttons: NodeListOf<HTMLButtonElement> = element.querySelectorAll("button");

  buttons.forEach(button => {
    button.disabled = false;
  });
}