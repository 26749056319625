const fullMediaVideoContainer = document.querySelectorAll(".full-media")

if(fullMediaVideoContainer){
const fullMediaVideoContainerArray = Array.from(fullMediaVideoContainer)

fullMediaVideoContainerArray.forEach(item => {
  const fullMediaButton = item.querySelector(".full-media__button") as HTMLElement
  const fullMediaContent = item.querySelector(".full-media__content") as HTMLElement
  const fullMediaVideo = item.querySelector(".full-media__video") as HTMLVideoElement

    item.addEventListener("click", e => {
      showFullMediaVideo(item)
  })

    function showFullMediaVideo(item) {
    item.classList.toggle("full-media--active")
    fullMediaButton.classList.toggle("full-media__button--active")
    fullMediaContent.classList.toggle("full-media__content--active")
    fullMediaVideo.classList.toggle("full-media__video--active")
    fullMediaVideo.play()
    fullMediaVideo.setAttribute("controls","controls")  
    }
  })
}