const featureBannerVideos = document.querySelectorAll(".feature-banner__video")
const featureBannerVideosMorning = document.querySelectorAll(".feature-banner__video-morning")
const featureBannerVideosAfternoon = document.querySelectorAll(".feature-banner__video-afternoon")
const featureBannerVideosEvening = document.querySelectorAll(".feature-banner__video-evening")

if(featureBannerVideos){
  getLocation()

  function getLocation() {
    const now = new Date();
    const currentHour = now.getHours();

    const featureBannerVideosArray = Array.from(featureBannerVideos)

    featureBannerVideosArray.forEach(item => {
      resetFeatureBannerVideo(item)

      switch (true) {
        case currentHour < 6:
          item.classList.contains("feature-banner__video-morning") && item.classList.add("feature-banner__video--active")
          break;
        case currentHour >= 6 && currentHour < 12:
          item.classList.contains("feature-banner__video-morning") && item.classList.add("feature-banner__video--active")
          break;
        case currentHour >= 12 && currentHour <= 17:
          item.classList.contains("feature-banner__video-afternoon") && item.classList.add("feature-banner__video--active")
          break;
        case currentHour > 17:
          item.classList.contains("feature-banner__video-evening") && item.classList.add("feature-banner__video--active")
          break;
        default:
          item.classList.contains("feature-banner__video-morning") && item.classList.add("feature-banner__video--active")
          break;
      } 
    }) 
  }

  function resetFeatureBannerVideo (item){
    item.classList.remove("feature-banner__video--active")
  }
}