import Glide from '@glidejs/glide';

const logoCarousels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.logo-carousel__inner');

if (logoCarousels.length > 0) {
    logoCarousels.forEach((item) => {
        var glide = new Glide(item, {
            type: 'slider',
            perView: 6,
            gap: 40,
            bound: true,
            rewind: true,
            breakpoints: {
                1300: {
                    perView: 5,
                },
                1100: {
                    perView: 4,
                },
                450: {
                    perView: 1.2,
                },
            },
        });

        glide.mount();
    });
}
