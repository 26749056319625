export const setRangeMax = (arr: NodeListOf<HTMLDivElement>, input: HTMLInputElement, perView: number) => {
  const totalSlides = arr.length;

  if (totalSlides <= perView) {
    input.max = '0';
  } else {
    const steps = Math.ceil(totalSlides - perView);
    input.max = steps.toString();
  }

  setRangeThumbWidth(Number(input.max), input);
}

export const handleCarouselButtons = (arr: NodeListOf<HTMLDivElement>, perView: number, carousel: HTMLElement, glide) => {
  const totalSlides = arr.length;
  const buttons = carousel.querySelector('[data-glide-el="controls"]') as HTMLElement;

  if (totalSlides <= perView) {
    buttons.classList.add('hidden');
    glide.disable()
  } else {
    buttons.classList.remove('hidden');
    glide.enable()
  }
}

const setRangeThumbWidth = (max: number, input: HTMLInputElement) => {
  const width = max === 1 ? 100 : 200 / max;
  input.style.setProperty('--thumb-size', `${width}px`);
}
