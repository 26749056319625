import Glide from '@glidejs/glide'

if(document.querySelector(".mini-icons-grid__carousel-inner")){
  var glide = new Glide('.mini-icons-grid__carousel-inner', {
    type: 'slider',
    perView: 3.5,
    gap: 20,
    rewind: false,
    breakpoints: {
      1200: {
        perView: 2.5,
      },
      760: {
        perView: 1.7,
      },
      550: {
        perView: 1.2,
      },
    }
  })
  
  glide.mount()

  const miniIconsCarouselRange = document.querySelector(".mini-icons-grid__carousel-range") as HTMLInputElement

  miniIconsCarouselRange.addEventListener("input", e => {
      setTimeout(() => {
        glide.go(`=${miniIconsCarouselRange.value}`)
      }, 500);
  })

  glide.on('move.after', () =>{
    const miniIconsCurrentIndex = glide.index;
    miniIconsCarouselRange.value = miniIconsCurrentIndex
  })
}

if(document.querySelector(".mini-icons-grid__carousel-inner")){
  const slides = document.querySelectorAll(".mini-icons-grid__carousel-card")
  const miniIconsCarouselRange = document.querySelector(".mini-icons-grid__carousel-range") as HTMLInputElement

  setRangeMax(slides, miniIconsCarouselRange)
}

function setRangeMax(arr, input) {
  const slidesLength = Array.from(arr).length
  const rangeMax = (slidesLength - 1).toString()
  input.max = rangeMax

  setRangeThumbWidth(rangeMax)
}

function setRangeThumbWidth(max) {
  const miniIconsCarouselRange = document.querySelector(".mini-icons-grid__carousel-range") as HTMLInputElement
  const width = 200 / +max
  miniIconsCarouselRange.style.setProperty('--thumb-size', width.toString() + "px");
  
  if(max === "1"){
    miniIconsCarouselRange.style.setProperty('--thumb-size', "100px");
  }
}