import Glide from '@glidejs/glide';

const fullMediaCarousels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.full-media-carousel__inner');

if (fullMediaCarousels.length > 0) {
    fullMediaCarousels.forEach((item) => {
        const mediaItems = item.querySelectorAll('.full-media-carousel__item');
        const leftButton = item.querySelector('.full-media-carousel__arrow-left');
        const rightButton = item.querySelector('.full-media-carousel__arrow-right');

        var glide = new Glide(item, {
            type: 'slider',
            perView: 1,
            gap: 0,
            rewind: false,
            bound: true,
        });

        if (mediaItems.length == 1) {
            leftButton.classList.add('hidden');
            rightButton.classList.add('hidden');
        }

        glide.mount();
    });
}
