const videoContainer = document.querySelector(".media-with-text__right") as HTMLElement
const placeholder = document.querySelector(".media-with-text__placeholder") as HTMLElement
const button = document.querySelector(".media-with-text__button") as HTMLElement
const video = document.querySelector(".media-with-text__video") as HTMLVideoElement

if(videoContainer){
  videoContainer.addEventListener("click", e => {
    if(video) {
      showVideo()
    }
  })

  function showVideo() {
    placeholder.classList.remove("media-with-text__placeholder--active")
    button.classList.remove("media-with-text__button--active")
    video.classList.add("media-with-text__video--active")
    video.play()
  }
}